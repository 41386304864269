<template>
  <section class="section6">
    <div class="marquee_wrap">
      <div id="marquee">
        <img v-for="(aImg, aIdx) in sImageList" :key="aIdx" :src="aImg" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sImageList: [
        require("../../img/ETRI_logo.png"),
        require("../../img/hankukcarbon_logo.png"),
        require("../../img/samsung.png"),
        require("../../img/hyundai_logo.png"),
        require("../../img/lotte_logo.png"),
        require("../../img/carrot.png"),
        require("../../img/daeati.png"),
        require("../../img/DSME.png"),
        require("../../img/hansol.png"),
        require("../../img/hkc.png"),
        require("../../img/HM.png"),
        require("../../img/huons.png"),
        require("../../img/hyundai_global.png"),
        require("../../img/hyundail_heavy.png"),
        require("../../img/hyundaiwia.png"),
        require("../../img/impix.png"),
        require("../../img/intellivix.jpg"),
        require("../../img/jonggendang.png"),
        require("../../img/kict.png"),
        require("../../img/kitech.png"),
        require("../../img/koen.png"),
        require("../../img/kolmar.png"),
        require("../../img/kepco.png"),
        require("../../img/korail.png"),
        require("../../img/korearail.png"),
        require("../../img/korens.png"),
        require("../../img/lae.png"),
        require("../../img/lotte.png"),
        require("../../img/lscable.png"),
        require("../../img/kolmar.png"),
        require("../../img/LS-ELECTRIC.png"),
        require("../../img/samsung.png"),
        require("../../img/seoul.png"),
        require("../../img/seoulmetro.png"),
        require("../../img/sk_bioscience.png"),
        require("../../img/SK-hynix_RGB_EN.png"),
        require("../../img/smapyo.jpg"),
        require("../../img/SMIC_logo.png"),
        require("../../img/snet.png"),
        require("../../img/tta.png"),
        require("../../img/woojin.png"),
        require("../../img/xslab_logo.png"),
      ],

      speed: 50,
    };
  },
  mounted() {
    this.setupMarquee();
  },
  methods: {
    setupMarquee() {
      const marquee = this.$el.querySelector("#marquee");
      const marqueeWidth = marquee.offsetWidth;
      let imagesWidth = 0;
      const images = marquee.querySelectorAll("img");
      images.forEach((image) => {
        imagesWidth += image.offsetWidth;
      });
      const totalWidth = imagesWidth + marqueeWidth * 2;
      marquee.innerHTML += marquee.innerHTML;
      marquee.style.width = totalWidth + "px";
      marquee.style.position = "relative";
      marquee.style.left = "0";
      this.startMarquee();
    },
    startMarquee() {
      setTimeout(() => {
        const marquee = this.$el.querySelector("#marquee");
        const currentPos = marquee.scrollLeft;
        if (currentPos >= marquee.offsetWidth) {
          marquee.scrollLeft = currentPos - marquee.offsetWidth;
        } else {
          marquee.scrollLeft = currentPos + 1;
        }
        this.startMarquee();
      }, 700 / this.speed);
    },
  },
};
</script>

<style scoped>
.marquee_wrap {
  padding: 50px 0;
  background-color: #fff;
  overflow: hidden;
}
#marquee {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  height: 80px;
}

#marquee img {
  flex-shrink: 0;
  margin-right: 100px;
}
</style>
