import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import store from "./store";
import { plugin, defaultConfig } from "@formkit/vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueCountryRegionSelect from "vue3-country-region-select";
import { createMetaManager } from "vue-meta";
import VueGtag from "vue-gtag-next";

export const app = createApp(App);
if (process.env.NODE_ENV == "production") {
    app.use(VueGtag, {
        property: { id: "G-BP3NTFVV8L" },
    });
}

createApp(App).use(router).use(plugin, defaultConfig).use(AOS).use(store).use(Toast).use(vueCountryRegionSelect).use(createMetaManager()).mount("#app");
createApp.use(createMetaManager, {
    attribute: "data-vue-meta",
});
