<template>
  <footer>
    <div class="footer_inner">
      <div class="logo">
        <img src="../img/machbase_logo_w.png" />
        <button class="contactus" @click="$router.push('/Contactus')">
          Contact Us
        </button>
      </div>
      <div>
        <p class="footertext">
          3003 North First street #206 San Jose, CA 95134. USA
        </p>
      </div>
      <div class="footer_box">
        <div class="footer_text">
          <!-- +1-408-621-0044 -->
          <p>MACHBASE.COM | sales@machbase.com | support@machbase.com</p>
          <p class="footer_margin_top">
            <span class="footer_visited">
              <a
                ><router-link
                  @click="goToPolicy(1)"
                  class="footer_visited"
                  to="/Policy"
                  >PRIVACY POLICY</router-link
                ></a
              >
            </span>
            |
            <span class="footer_visited">
              <a
                ><router-link
                  @click="goToPolicy(2)"
                  class="footer_visited"
                  to="/Policy"
                  >REFUNDS</router-link
                ></a
              >
            </span>
          </p>
        </div>
        <div class="sns">
          <div>
            <a href="https://twitter.com/machbase" target="_blank"
              ><img src="../img/twitter.png"
            /></a>
          </div>
          <div>
            <a href="https://github.com/machbase" target="_blank"
              ><img src="../img/github.png"
            /></a>
          </div>
          <div>
            <a href="https://www.linkedin.com/company/machbase" target="_blank"
              ><img src="../img/linkedin.png"
            /></a>
          </div>
          <div>
            <a href="https://www.facebook.com/MACHBASE/" target="_blank"
              ><img src="../img/facebook.png"
            /></a>
          </div>
          <div>
            <a href="https://www.slideshare.net/machbase" target="_blank"
              ><img src="../img/slideshare.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_tablet_inner">
      <div class="logo">
        <img src="../img/machbase_logo_w.png" />
      </div>
      <div>
        <p class="footertext">
          3003 North First street #206 San Jose, CA 95134. USA
        </p>
      </div>
      <div class="footer_box">
        <div class="footer_text">
          <!-- +1-408-621-0044  -->
          <p>MACHBASE.COM | sales@machbase.com | support@machbase.com</p>
          <p class="footer_margin_top">
            <a
              ><router-link
                @click="goToPolicy(1)"
                class="footer_visited"
                to="/Policy"
                >PRIVACY POLICY</router-link
              ></a
            >
            |
            <a
              ><router-link
                @click="goToPolicy(2)"
                class="footer_visited"
                to="/Policy"
                >REFUNDS</router-link
              ></a
            >
          </p>
        </div>
        <div class="sns">
          <div>
            <a href="https://twitter.com/machbase" target="_blank"
              ><img src="../img/twitter.png"
            /></a>
          </div>
          <div>
            <a href="https://github.com/machbase" target="_blank"
              ><img src="../img/github.png"
            /></a>
          </div>
          <div>
            <a href="https://www.linkedin.com/company/machbase" target="_blank"
              ><img src="../img/linkedin.png"
            /></a>
          </div>
          <div>
            <a href="https://www.facebook.com/MACHBASE/" target="_blank"
              ><img src="../img/facebook.png"
            /></a>
          </div>
          <div>
            <a href="https://www.slideshare.net/machbase" target="_blank"
              ><img src="../img/slideshare.png"
            /></a>
          </div>
        </div>
      </div>
      <button @click="$router.push('/Contactus')" class="contactus">
        Contact US
      </button>
    </div>
    <div class="machbase_right">
      <p>@2023 MACHBASE All rights reserved.</p>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    goToPolicy(selectedTab) {
      this.$router.push({
        name: "Policy",
        query: { selectedTab: selectedTab },
      });
    },
  },
};
</script>
