import { createStore } from "vuex";

export default createStore({
  state: {
    radioValue: "",
    quantity: 1,
  },
  mutations: {
    setPriceInfo(state, aValue) {
      state.radioValue = aValue;
    },
    setQuantity(state, aNumber) {
      state.quantity = aNumber;
    },
  },
});
