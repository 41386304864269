import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: { title: "Mahcbase" },
    },
    // {
    //     path: "/kr",
    //     name: "homeKr",
    //     component: () => import("../views/HomeKrView.vue"),
    //     meta: { title: "Mahcbase" },
    // },
    {
        path: "/Products",
        name: "Products",
        component: () => import("../views/ProductsView.vue"),
        meta: { showLoader: true },
    },
    // {
    //     path: "/kr/products",
    //     name: "ProductsKr",
    //     component: () => import("../views/ProductsKrView.vue"),
    //     meta: { showLoader: true },
    // },
    {
        path: "/Document",
        name: "Document",
        component: () => import("../views/DocumentView.vue"),
        meta: { showLoader: true },
    },
    // {
    //     path: "/kr/document",
    //     name: "DocumentKr",
    //     component: () => import("../views/DocumentKrView.vue"),
    //     meta: { showLoader: true },
    // },
    {
        path: "/Usecase",
        name: "Usecase",
        component: () => import("../views/MediumView.vue"),
        meta: { showLoader: true },
    },
    // {
    //     path: "/kr/usecase",
    //     name: "UsecaseKr",
    //     component: () => import("../views/MediumKrView.vue"),
    //     meta: { showLoader: true },
    // },
    {
        path: "/Customers",
        name: "Customers",
        component: () => import("../views/CustomersView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Company",
        name: "Company",
        component: () => import("../views/CompanyView.vue"),
        meta: { showLoader: true },
    },
    // {
    //     path: "/kr/company",
    //     name: "CompanyKr",
    //     component: () => import("../views/CompanyKrView.vue"),
    //     meta: { showLoader: true },
    // },
    {
        path: "/Download",
        name: "Download",
        component: () => import("../views/DownloadView.vue"),
        meta: { showLoader: true },
    },
    // {
    //     path: "/kr/download",
    //     name: "DownloadKr",
    //     component: () => import("../views/DownloadKrView.vue"),
    //     meta: { showLoader: true },
    // },
    // {
    //     path: "/kr/contactus",
    //     name: "ContactusKr",
    //     component: () => import("../views/ContactusKrView.vue"),
    //     meta: { showLoader: true },
    // },
    {
        path: "/Contactus",
        name: "Contactus",
        component: () => import("../views/ContactusView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Store",
        name: "Store",
        component: () => import("../views/StoreView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/mos",
        name: "mos",
        component: () => import("../views/MosView.vue"),
    },
    // {
    //     path: "/kr/mos",
    //     name: "mosKr",
    //     component: () => import("../views/MosKrView.vue"),
    // },
    {
        path: "/Pricing",
        name: "Pricing",
        component: () => import("../views/PricingView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Purchase",
        name: "Purchase",
        component: () => import("../views/PurchaseView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Recipientinformation",
        name: "RecipientInformation",
        component: () => import("../views/RecipientInformationView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Complete",
        name: "Complete",
        component: () => import("../views/CompleteView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/Fail",
        name: "Fail",
        component: () => import("../views/FailView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Not-found",
        component: () => import("../views/PageNotFoundView.vue"),
        meta: { showLoader: true },
    },
    {
        path: "/path",
        name: "Not",
        component: () => import("../views/PageView.vue"),
    },
    {
        path: "/Policy",
        name: "Policy",
        component: () => import("../views/privacyPolicyView.vue"),
    },
    {
        path: "/edge",
        name: "Edge",
        component: () => import("../views/EdgeView.vue"),
    },
    {
        path: "/Blog",
        name: "blog",
        component: () => import("../views/TechBlog.vue"),
    },
    // {
    //     path: "/kr/blog",
    //     name: "blogKr",
    //     component: () => import("../views/TechBlogKr.vue"),
    // },
    {
        path: "/deep-anomaly-detection-in-time-series-1",
        name: "Deep-anomaly-detection-in-time-series-1",
        component: () => import("../views/TechBlogAnomaly1View.vue"),
    },
    // {
    //     path: "/kr/deep-anomaly-detection-in-time-series-1",
    //     name: "Deep-anomaly-detection-in-time-series-1Kr",
    //     component: () => import("../views/TechBlogAnomaly1KrView.vue"),
    // },
    {
        path: "/deep-anomaly-detection-in-time-series-2",
        name: "Deep-anomaly-detection-in-time-series-2",
        component: () => import("../views/TechBlogAnomalyView.vue"),
    },
    // {
    //     path: "/kr/deep-anomaly-detection-in-time-series-2",
    //     name: "Deep-anomaly-detection-in-time-series-2Kr",
    //     component: () => import("../views/TechBlogAnomalyKrView.vue"),
    // },
    {
        path: "/comparing-time-series-database",
        name: "comparing-time-series-database",
        component: () => import("../views/TechBlogMvsI.vue"),
    },
    // {
    //     path: "/kr/comparing-time-series-database",
    //     name: "comparing-time-series-databaseKr",
    //     component: () => import("../views/TechBlogMvsIKr.vue"),
    // },
    {
        path: "/managing-and-retrieving",
        name: "managing-and-retrieving",
        component: () => import("../views/TechBlogManagingRetrievingSemiconductor.vue"),
    },
    // {
    //     path: "/kr/managing-and-retrieving",
    //     name: "managing-and-retrievingKr",
    //     component: () => import("../views/TechBlogManagingRetrievingSemiconductorKr.vue"),
    // },
    {
        path: "/why-did-machbase-neo-switch",
        name: "why-did-machbase-neo-switch",
        component: () => import("../views/TechBlogNeofirstView.vue"),
    },
    // {
    //     path: "/kr/why-did-machbase-neo-switch",
    //     name: "why-did-machbase-neo-switchKr",
    //     component: () => import("../views/TechBlogNeofirstKrView.vue"),
    // },
    {
        path: "/usecase-c",
        name: "Usecase-C",
        component: () => import("../views/UsecaseC.vue"),
    },
    // {
    //     path: "/kr/usecase-c",
    //     name: "Usecase-CKr",
    //     component: () => import("../views/UsecaseCKr.vue"),
    // },
    {
        path: "/usecase-m",
        name: "Usecase-M",
        component: () => import("../views/UsecaseM.vue"),
    },
    // {
    //     path: "/kr/usecase-m",
    //     name: "Usecase-MKr",
    //     component: () => import("../views/UsecaseMKr.vue"),
    // },
    {
        path: "/usecase-l",
        name: "Usecase-L",
        component: () => import("../views/UsecaseL.vue"),
    },
    // {
    //     path: "/kr/usecase-l",
    //     name: "Usecase-LKr",
    //     component: () => import("../views/UsecaseLKr.vue"),
    // },
    {
        path: "/usecase-hc",
        name: "Usecase-Hc",
        component: () => import("../views/UsecaseHc.vue"),
    },
    // {
    //     path: "/usecase-hcKr",
    //     name: "Usecase-HcKr",
    //     component: () => import("../views/UsecaseHcKr.vue"),
    // },
    {
        path: "/usecase-h",
        name: "Usecase-H",
        component: () => import("../views/UsecaseH.vue"),
    },
    // {
    //     path: "/kr/usecase-h",
    //     name: "Usecase-HKr",
    //     component: () => import("../views/UsecaseHKr.vue"),
    // },
    {
        path: "/usecase-e",
        name: "Usecase-E",
        component: () => import("../views/UsecaseE.vue"),
    },
    // {
    //     path: "/kr/usecase-e",
    //     name: "Usecase-EKr",
    //     component: () => import("../views/UsecaseEKr.vue"),
    // },

    {
        path: "/neo-worksheet",
        name: "neo-worksheet",
        component: () => import("../views/TechBlogNeoWorksheet.vue"),
    },
    // {
    //     path: "/kr/neo-worksheet",
    //     name: "neo-worksheetKr",
    //     component: () => import("../views/TechBlogNeoWorksheetKr.vue"),
    // },

    // {
    //     path: "/kr/database-for-things1",
    //     name: "database-for-things1Kr",
    //     component: () => import("../views/TechBlogDatabaseFor1Kr.vue"),
    // },
    {
        path: "/database-for-things1",
        name: "database-for-things1",
        component: () => import("../views/TechBlogDatabaseFor1.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
            };
        } else {
            return { top: 0 };
        }
    },
});

export default router;
