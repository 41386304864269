<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content}: Time Series Data Platform` : `Machbase` }}</template>
    </metainfo>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta meta name="description" content="Machbase. The Unparalleled Time Series DBMS for Edge Computing!" />
        <meta name="keywords" content="Machbase, raspberry pi, embedded, TSDB" />
        <link rel="icon" href="./img/favicon.ico" />
    </head>
    <!-- :class="{ scrolled: isScrolled }"> -->
    <div class="menu scrolled">
        <div class="header">
            <router-link to="/"><img class="top_logo" alt="machbase logo" src="./img/machbase_logo_w.png" /></router-link>
            <router-link to="/"><img class="sticky_logo" alt="machbase logo" src="./img/machbase_logo_b.png" /></router-link>
            <nav class="left">
                <div class="products-menu-wrap">
                    <a class="menu_active_border" @mouseenter="showDropdown = true" @mouseleave="showDropdown = false">
                        <div>
                            <router-link to="/Products">Products</router-link>
                            <span v-if="$route && $route.name && $route.name.toUpperCase() === 'PRODUCTS'" class="menu_active_border-underline"></span>
                        </div>
                    </a>
                    <div class="dropdown" v-if="showDropdown" @mouseenter="showDropdown = true" @mouseleave="showDropdown = false">
                        <a class="dropdown-link" href="/Products">TSDB</a>
                        <a class="dropdown-link" href="/mos">MOS</a>
                        <a class="dropdown-link" href="https://www.cems.ai/" target="_blank">CEMS</a>
                    </div>
                </div>
                <a class="menu_active_border">
                    <router-link to="/Document">Docs</router-link>
                    <span v-if="$route && $route.name && $route.name.toUpperCase() === 'DOCUMENT'" class="menu_active_border-underline"></span>
                </a>
                <a class="menu_active_border">
                    <router-link to="/Blog">Blog</router-link>
                    <span v-if="$route && $route.name && $route.name.toUpperCase() === 'BLOG'" class="menu_active_border-underline"></span>
                </a>
                <!-- <div v-if="serverState === 'dev'">
                    <a class="menu_active_border">
                        <router-link to="/Pricing">Pricing</router-link>
                        <span v-if="$route && $route.name && $route.name.toUpperCase() === 'PRICING'" class="menu_active_border-underline"></span>
                    </a>
                    <a class="menu_active_border">
                        <router-link to="/Membership">MEMBERSHIP</router-link>
                        <span v-if="$route && $route.name && $route.name.toUpperCase() === 'MEMBERSHIP'" class="menu_active_border-underline"></span>
                    </a>
                </div> -->
                <a class="menu_active_border">
                    <router-link to="/Customers">Customers</router-link>
                    <span v-if="$route && $route.name && $route.name.toUpperCase() === 'CUSTOMERS'" class="menu_active_border-underline"></span>
                </a>
                <a class="menu_active_border">
                    <router-link to="/Usecase">Use case</router-link>
                    <span v-if="$route && $route.name && $route.name.toUpperCase() === 'USECASE'" class="menu_active_border-underline"></span>
                </a>
                <a class="menu_active_border">
                    <router-link to="/Company">Company</router-link>
                    <span v-if="$route && $route.name && $route.name.toUpperCase() === 'COMPANY'" class="menu_active_border-underline"></span>
                </a>
            </nav>
        </div>
        <!-- <img class="lang_btn" src="./img/lang_btn.png" />
    <img class="lang_btn_b" src="./img/lang_btn_b.png" /> -->
    </div>
    <div class="right">
        <button @click="$router.push('/Download'), (menuOpen = false)" class="download_menu">
            <img class="right_img" alt="download img" src="./img/download.png" />
            <img class="tablet_right_img" alt="download img" src="./img/tablet_download.png" />
            <p>DOWNLOAD</p>
        </button>
        <div v-if="serverState === 'dev'">
            <button @click="$router.push('/Store'), (menuOpen = false)" class="store_menu">
                <img class="right_img" alt="shopping img" src="./img/shopping.png" />
                <img class="tablet_right_img" alt="shopping img" src="./img/tablet_store.png" />
                <p>STORE</p>
            </button>
        </div>
        <button onclick="window.open('https://machbase.zendesk.com/hc/en-us')" @click="menuOpen = false" class="support_menu">
            <img class="right_img" src="./img/support.png" />
            <img class="tablet_right_img" src="./img/tablet_support.png" />
            <p>SUPPORT</p>
        </button>
    </div>
    <router-view />

    <nav class="tablemenu" :class="{ scrolled: isScrolled }">
        <div class="tablelogo">
            <router-link to="/"><img src="./img/mainlogo_sw.png" /></router-link>
        </div>
        <div class="sticky_logo">
            <div class="sticky">
                <router-link to="/"><img src="./img/mainlogo_sb.png" /></router-link>
            </div>
        </div>
        <div class="burger">
            <div class="burger-menu" :class="{ 'is-active': menuOpen }">
                <div class="burger-menu-header">
                    <router-link to="/" @click="menuOpen = false"><img class="table_logo" src="./img/mainlogo_sb.png" /></router-link>
                    <button @click="toggleMenu">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <ul class="burger-menu-list">
                    <div v-if="serverState === 'dev'">
                        <li v-for="item in menuItems" :key="item.title">
                            <router-link :to="item.path" @click="menuOpen = false">{{ item.title }}</router-link>
                        </li>
                    </div>
                    <div v-else-if="serverState === 'prod'">
                        <li v-for="item in menuItems" :key="item.title">
                            <div v-if="item.title === 'Pricing' || item.title === 'Membership'">
                                <!-- Nothing to do -->
                            </div>
                            <div v-else>
                                <router-link :to="item.path" @click="menuOpen = false">{{ item.title }}</router-link>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>
            <button @click="toggleMenu" :class="{ 'is-active': menuOpen }" class="burger-button">
                <span class="burger-bar"></span>
                <span class="burger-bar"></span>
                <span class="burger-bar"></span>
            </button>
        </div>
    </nav>
</template>
<style>
@import "./css/common.css";
@import "./css/style.css";
</style>
<script>
export default {
    name: "app",

    data() {
        return {
            isScrolled: false,
            menuOpen: false,
            serverState: process.env.VUE_APP_SERVER_STATE,
            menuItems: [
                { title: "TSDB", path: "/Products" },
                { title: "MOS", path: "/mos" },
                { title: "Document", path: "/Document" },
                // { title: "Pricing", path: "/Pricing" },
                { title: "Blog", path: "/Blog" },

                //{ title: "Membership", path: "/Membership" },
                { title: "Customers", path: "/Customers" },
                { title: "Use case", path: "/Usecase" },
                { title: "Company", path: "/Company" },
            ],
            showDropdown: false,
            // sRouter: router,
        };
    },
    // watch: {
    //     $route() {
    //         window.scrollTo(0, 0);
    //     },
    // },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        adjustContentPosition() {
            const content = this.$refs.content;
            if (content) {
                content.style.paddingTop = `${this.$refs.menu.clientHeight}px`;
            }
        },
        closeMenu() {},
        handleScroll() {
            this.isScrolled = window.scrollY > 0;
        },
        upperName(aItem) {
            return aItem.toUpperCase();
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style>
.menu_active_border {
    position: relative;
}

.menu_active_border-underline {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 3px;
    background-color: #ffd800;
}
.products-menu-wrap {
    display: flex;
    flex-direction: column;
}
.dropdown {
    display: flex;
    flex-direction: column;
}

.dropdown-link {
    /* padding-bottom: 25px; */
    color: #253fd0;
    background-color: #fff;
    box-shadow: 0 6px 12px 0 rgba(37, 63, 208, 0.2);
    line-height: 60px;
}

.dropdown-link:first-child {
    margin-top: 39px;
    border-top: 3px solid #ffd800 !important;
}
</style>
